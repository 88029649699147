/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    ol: "ol",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "поздравляем-вы-выиграли-бесплатную-копию-arcpanel",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%D0%BF%D0%BE%D0%B7%D0%B4%D1%80%D0%B0%D0%B2%D0%BB%D1%8F%D0%B5%D0%BC-%D0%B2%D1%8B-%D0%B2%D1%8B%D0%B8%D0%B3%D1%80%D0%B0%D0%BB%D0%B8-%D0%B1%D0%B5%D1%81%D0%BF%D0%BB%D0%B0%D1%82%D0%BD%D1%83%D1%8E-%D0%BA%D0%BE%D0%BF%D0%B8%D1%8E-arcpanel",
    "aria-label": "поздравляем вы выиграли бесплатную копию arcpanel permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Поздравляем, вы выиграли бесплатную копию ARCPanel!"), "\n", React.createElement(_components.p, null, "Вот как вы можете активировать ваш ключ:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Скачайте бесплатную копию ARCPanel с https://arcpanel.averin.photo/ru."), "\n", React.createElement(_components.li, null, "Установите панель, следуя ", React.createElement(_components.a, {
    href: "https://arcpanel.averin.photo/ru/blog/2019-03-30-installation-instructions/"
  }, "инструкциям по установке"), "."), "\n", React.createElement(_components.li, null, "Запустите панель в Adobe Photoshop и введите ваш ключ в поле для активации."), "\n", React.createElement(_components.li, null, "Панель предложит зайти на сайт, где вам нужно создать личный аккаунт. Это необходимо чтобы вы никогда не потеряли ваш лицензионный ключ. Вы можете зарегистрироваться с помощью Google или Facebook, или же с через обычный Email."), "\n", React.createElement(_components.li, null, "После создания аккаунта ваш ключ будет активирован."), "\n"), "\n", React.createElement(_components.p, null, "Подробнее с функционалом панели можно ознакомиться на ", React.createElement(_components.a, {
    href: "https://arcpanel.averin.photo/ru"
  }, "сайте"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
